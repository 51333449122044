/*
 * @Author: 智客云网络科技
 * @Date: 2021-04-19 11:07:53 +0800
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-01-22 00:06:38
 * @Description: 页面专属接口管理
 * @FilePath: \src\views\Frame\Menu\api.js
 */

import http from "@/api";

// 创建新菜单
export const createNewMenu = (params) =>
  http.post("/v1/Menu/CreateMenu", params);

// 修改菜单
export const updateMenu = (params) => http.put("/v1/Menu/UpdateMenu", params);

// 删除菜单
export const delMenu = (params) => http.delete("/v1/Menu/DelMenu", params);

// 获取菜单表格
export const getMenuTable = (params) =>
  http.get("/v1/Menu/GetMenuTable", params);

// 获取根菜单
export const getAllMenuFolder = (params) =>
  http.get("/v1/Menu/GetAllMenuFolder", params);

// 根据ID查询指定菜单参数
export const getMenuInfo = (params) =>
  http.get("/v1/Menu/GetMenuInfoById", params);
