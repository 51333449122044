<!--
 * @Author: 智客云网络科技
 * @Date: 2021-04-17 22:04:49 +0800
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-16 16:11:42
 * @Description: 创建 修改 菜单
 * @FilePath: \src\views\Frame\Menu\NUMenu.vue
-->
<template>
  <el-dialog
    v-drag2anywhere
    :title="title"
    :visible.sync="isShow"
    width="950px"
    top="10vh"
    append-to-body
    destroy-on-close
    :close-on-click-modal="false"
    @close="_HandleClose()"
    custom-class="full-dialog"
  >
    <div class="vue-box">
      <el-card
        shadow="never"
        v-loading="loading"
        element-loading-text="拼命加载中..."
      >
        <el-form :model="m" :inline="true" ref="m" label-width="100px">
          <el-form-item label="上级菜单：" style="width: 45%">
            <el-select size="small" v-model="m.parent_id">
              <el-option label="主目录" :value="'topmenu'"></el-option>
              <el-option
                v-for="item in topMenuList"
                :key="item.kmenu_id"
                :label="item.kme_name"
                :value="item.kmenu_id"
              >
                <i :class="item.icon"></i> {{ item.kme_name }}
              </el-option>
            </el-select>
            <el-tooltip
              class="item"
              effect="dark"
              content="刷新列表"
              placement="top-start"
            >
              <el-button
                size="small"
                icon="el-icon-refresh"
                @click="_GetAllMenuFolder()"
              ></el-button>
            </el-tooltip>
          </el-form-item>

          <el-form-item label="菜单类型：" v-if="id === 0" style="width: 45%">
            <el-radio v-model="m.type" :label="104010">目录</el-radio>
            <el-radio v-model="m.type" :label="104011">菜单项</el-radio>
          </el-form-item>

          <div class="icon-picker-box">
            <el-form-item
              label="组件图标："
              class="icon-picker-fitem"
              v-if="m.parent_id === 'topmenu'"
            >
              <e-icon-picker v-model="m.icon" />
            </el-form-item>
          </div>

          <el-form-item
            label="菜单名称："
            style="width: 96.5%"
            class="route-box"
          >
            <el-input
              size="small"
              v-model="m.name"
              suffix-icon="xxxx"
            ></el-input>
          </el-form-item>

          <el-form-item label="显示排序：" prop="sort" style="width: 96.5%">
            <el-input-number
              v-model="m.sort"
              size="small"
              :min="1"
              :max="999999"
            ></el-input-number>
          </el-form-item>

          <el-form-item
            v-if="m.type === 104011"
            style="width: 96.5%"
            class="route-box"
          >
            <template #label>
              <el-tooltip
                class="item"
                effect="dark"
                content="访问的路由地址，如：`Home`"
                placement="top"
              >
                <i class="el el-icon-question"></i>
              </el-tooltip>
              路由地址：
            </template>
            <el-input
              size="small"
              v-model="m.route"
              suffix-icon="xxxx"
            ></el-input>
          </el-form-item>

          <el-form-item
            v-if="m.type === 104011"
            style="width: 96.5%"
            class="route-box"
          >
            <template #label>
              <el-tooltip
                class="item"
                effect="dark"
                content="访问的组件路径，如：`dashboard/Workplace`，默认在`views`目录下"
                placement="top"
              >
                <i class="el el-icon-question"></i>
              </el-tooltip>
              组件路径：
            </template>
            <el-input
              size="small"
              v-model="m.uri"
              suffix-icon="xxxx"
            ></el-input>
          </el-form-item>

          <el-form-item label="菜单状态：" style="width: 30%">
            <el-switch
              size="small"
              v-model="m.status"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
          </el-form-item>

          <el-form-item label="显示状态：" style="width: 30%">
            <el-switch
              size="small"
              v-model="m.show"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
          </el-form-item>

          <el-form-item label="认证状态：" style="width: 30%">
            <el-switch
              size="small"
              v-model="m.verify"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
          </el-form-item>
        </el-form>

        <el-divider></el-divider>

        <el-button
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="_CreateNewMenu()"
          v-if="id == 0"
          >提交</el-button
        >
        <el-button
          size="small"
          type="primary"
          icon="el-icon-check"
          @click="_UpdateMenu()"
          v-else
          >保存</el-button
        >
        <el-button size="small" icon="el-icon-close" @click="isShow = false"
          >取消</el-button
        >
      </el-card>
    </div>
  </el-dialog>
</template>

<script>
import {
  createNewMenu,
  updateMenu,
  getAllMenuFolder,
  getMenuInfo,
} from "./api"; //页面专有接口
export default {
  data() {
    return {
      m: {
        parent_id: "topmenu", //上级菜单ID
        type: 104010, //菜单类型
        icon: "", //菜单图标
        name: "", //菜单名称
        sort: "", //显示排序
        route: "", //路由地址
        uri: "", //组件路径
        status: 1, //菜单状态
        show: 1, //显示状态
        verify: 1, //认证状态
      },
      id: 0, //
      title: "", //弹窗标题
      isShow: false, //显示控制
      loading: true, //加载控制
      topMenuList: [], //顶级菜单列表
    };
  },
  methods: {
    //打开组件
    open(id) {
      this.isShow = true; //显示组件
      this.id = id; //重写ID
      this._GetAllMenuFolder(); //获取所有顶级菜单列表
      this.$nextTick(() => {
        if (id === 0) {
          //0 新建
          this.title = "新建菜单";
          this.loading = false;
        } else {
          //修改
          this.title = "修改菜单";
          this._GetMenuInfo(id);
        }
      });
    },

    //创建新菜单
    _CreateNewMenu() {
      var m = this.m;
      createNewMenu({
        parent_id: m.parent_id, //上级菜单ID
        type: m.type, //菜单类型
        icon: m.icon, //菜单图标
        name: m.name, //菜单名称
        sort: m.sort, //显示排序
        route: m.route, //路由地址
        uri: m.uri, //组件路径
        verify: m.verify, //认证状态
        show: m.show, //显示状态
        status: m.status, //菜单状态
      })
        .then((res) => {
          this.$utils.success(res.err_msg);
          this.isShow = false;
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //修改菜单
    _UpdateMenu() {
      var m = this.m;
      updateMenu({
        menu_id: this.id, //菜单ID
        parent_id: m.parent_id, //上级菜单ID
        type: m.type, //菜单类型
        icon: m.icon, //菜单图标
        name: m.name, //菜单名称
        sort: m.sort, //显示排序
        route: m.route, //路由地址
        uri: m.uri, //组件路径
        status: m.status, //菜单状态
        show: m.show, //显示状态
        verify: m.verify, //认证状态
      })
        .then((res) => {
          this.$utils.success(res.err_msg);
          this.isShow = false;
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //获取顶级菜单列表
    _GetAllMenuFolder() {
      getAllMenuFolder({ ban_menu_id: this.id })
        .then((res) => {
          this.topMenuList = Object.values(res.data); //写入菜单列表
          //   this.m.parent_id = "topmenu"; //重置上级菜单
        })
        .catch((err) => {
          if (err.code !== 104015) {
            this.isShow = false;
            this.$utils.error(err.err_msg);
          }
        });
    },

    //获取指定菜单参数
    _GetMenuInfo(id) {
      getMenuInfo({
        menu_id: id,
      })
        .then((res) => {
          let m = this.m;
          let d = res.data;
          m.parent_id = d.kme_parent; //上级菜单ID
          m.type = d.kme_type; //菜单类型
          m.icon = d.kme_icon; //菜单图标
          m.name = d.kme_name; //菜单名称
          m.sort = d.kme_sort; //显示排序
          m.route = d.kme_route; //路由地址
          m.uri = d.kme_uri; //组件路径
          m.status = d.kme_status; //菜单状态
          m.show = d.kme_show; //显示状态
          m.verify = d.kme_verify; //认证状态
          this.loading = false;
        })
        .catch((err) => {
          this.isShow = false;
          this.$utils.error(err.err_msg);
        });
    },

    //关闭
    _HandleClose() {
      Object.assign(this.$data, this.$options.data());
      this.$parent._Load(); //通知父组件刷新数据
    },
  },
};
</script>
<style>
.route-box .el-form-item__content {
  width: calc(100% - 100px);
}
</style>
